import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "re-enter-the-kingdom",
      "style": {
        "position": "relative"
      }
    }}>{`Re-enter the Kingdom`}<a parentName="h1" {...{
        "href": "#re-enter-the-kingdom",
        "aria-label": "re enter the kingdom permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "why",
      "style": {
        "position": "relative"
      }
    }}>{`Why`}<a parentName="h2" {...{
        "href": "#why",
        "aria-label": "why permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We’ve learnt about the basic ways we can attack smart contracts. We’ve deployed our own contracts to practice complementary opposites in public. We’ve seen deep into the permanent and public storage of all things. Now it’s time to fallback on the most classic vulnerability there is and re-enter consciously the hack which nearly unravelled it all.`}</p>
    <p>{`We’ll be playing for the throne, going for the top floor, and re-entering a contract to claim it all. This truly is the climax. Who knew smart contracts could present such a dramatic soap opera?`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Ensure that you still have some Sepolia ETH left in your account.`}</li>
      <li parentName="ol">{`Read about The DAO hack.`}</li>
      <li parentName="ol">{`Sit and breathe quietly for at least 20 minutes. Use this `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=g_LNp8xY1YM"
        }}>{`music`}</a>{` if it helps. Or `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=b5H3b_Hh0Lw"
        }}>{`this`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h2" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We’re going to investigate more advanced hacks using fallback functions - or the lack thereof - and patterns in functions which don’t protect adequately against them.  We’ll also be looking at some more footguns in Solidity, like misleading scope modifiers and interfering interfaces.`}</p>
    <p>{`In writing some more advanced attack contracts, you will be learning both how to write your own contracts and do so more securely, at the same time. If time allows, we’ll also take a look at the Remix debugger and try and step through a function call to trace what happens in the EVM.`}</p>
    <h2 {...{
      "id": "summation",
      "style": {
        "position": "relative"
      }
    }}>{`Summation`}<a parentName="h2" {...{
        "href": "#summation",
        "aria-label": "summation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In this session, you will have learned:`}</p>
    <ol>
      <li parentName="ol">{`More complex ways to use fallback functions to exploit poor code.`}</li>
      <li parentName="ol">{`What kinds of patterns to avoid when writing your own contracts.`}</li>
      <li parentName="ol">{`How to use more of the functions in Remix to assist you in your own development.`}</li>
      <li parentName="ol">{`Why reading code is a multi-layered affair, and how nuanced trust really is.`}</li>
      <li parentName="ol">{`How to think in the sort of logical patterns good developers make a habit of.`}</li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/DAq1LcrxUZs" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/tat_tvam_asi.jpg",
        "alt": "It Is Within You"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      